<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>{{this.plural}}</h1>
          <v-btn
              v-if="isAllowed('product','c')"
            class="mx-2"
            fab
            small
            dark
            color="info"
            :to="`/${this.pluralLower}/create`"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn class="ml-2 mr-1" dark color="info" to="/productattributes">Attributes</v-btn>
          <v-btn class="mx-1" dark color="info" to="/producttags">Tags</v-btn>
          <v-btn class="mx-1" dark color="info" to="/productcategories">Categories</v-btn>
          <v-btn class="mx-1" dark color="info" to="/brands">Brands</v-btn>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
<!--        <v-col cols="2">-->
<!--          <span>Load By:</span>-->
<!--          <v-radio-group style="margin-top: -5px;" v-model="productGrouping" row @change="loadNewGroup">-->
<!--            <v-radio label="Suppliers" value="supplier"/>-->
<!--            <v-radio label="Brands" value="brand"/>-->
<!--          </v-radio-group>-->
<!--          <h3>{{productGrouping==="supplier"?'Suppliers ('+suppliers.length+')':'Brands ('+brands.length+')'}}</h3>-->
<!--          <v-text-field outlined :label="'Search '+(productGrouping==='supplier'?'Suppliers':'Brands')" v-model="groupSearch" @input="searchGroup"/>-->
<!--          <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column">-->
<!--            <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" class="d-flex flex-row align-center justify-space-between" v-for="item in filteredGroup" :key="item.id">-->
<!--              <span>{{productGrouping==="supplier"?item.name:item.name}}</span>-->
<!--              <v-btn @click="loadProductsFromGroup(item)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>-->
<!--            </div>-->
<!--          </div>-->
<!--        </v-col>-->
        <v-col>
          <v-card class="d-flex flex-column" style="margin-bottom: 180px;">
            <v-card-title>
              <span class="d-flex flex-column align-center">
                <span class="d-flex flex-row align-center">
                  {{tableTitle}}
                  <v-switch class="ml-3" label="Show Filters" v-model="showFilters" @change="updateShowHideFilters"></v-switch>
                </span>
                <span class="d-flex flex-row" style="width: 100%;">
                  <v-text-field
                      v-model="queryData.searchTerm"
                      label="Search"
                      single-line
                      outlined
                      clearable
                      dense
                      hide-details
                      style="width: 100%"
                      @change="searchProducts"
                  ></v-text-field>
                  <v-btn class="ml-2" color="info" fab x-small @click="searchProducts"><v-icon>mdi-magnify</v-icon></v-btn>
                </span>
              </span>
              <v-spacer></v-spacer>
              <span class="mt-4 d-flex flex-row justify-center">
                <v-pagination
                  :disabled="tableLoading"
                  @input="searchProducts('pageChange')"
                  v-model="queryData.page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                />
                <v-select outlined dense label="Sort By" style="max-width: 200px; border-bottom-right-radius: 0; border-top-right-radius: 0;" return-object :items="sorting" v-model="customSort" @change="updateCustomSort"/>
                <v-btn class="mr-2" @click="changeOrder" :disabled="tableLoading" height="40" min-width="1" outlined style="border-bottom-left-radius: 0; border-top-left-radius: 0;" text color="info"><v-icon>{{queryData.order?`mdi-sort-${queryData.sort==="name"?"alphabetical-":""}ascending`:`mdi-sort-${queryData.sort==="name"?"alphabetical-":""}descending`}}</v-icon></v-btn>
                <v-select outlined dense label="List Size" style="max-width: 100px;" return-object :items="limits" v-model="queryData.limit" @change="searchProducts"/>
              </span>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="products"
                :page.sync="queryData.page"
                :items-per-page="queryData.limit.value"
                :loading="tableLoading"
                hide-default-footer
                :sort-by.sync="tableOptions.sort"
                :sort-desc.sync="tableOptions.order"
                @update:options="updateTableOptions"
            >
              <template v-slot:item.name="props">
                <v-edit-dialog
                    :return-value.sync="props.item.Product.name"
                    large
                    persistent
                    @save="saveName(props)"
                    @cancel="cancel"
                >
                  <div>{{ props.item.Product.name }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Name
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.Product.name"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-if="isAllowed('product', 'viewCostPrice')" v-slot:item.costPrice="props">
                <v-edit-dialog
                    :return-value.sync="props.item.costPrice"
                    large
                    persistent
                    @save="saveCostPrice(props)"
                    @cancel="cancel"
                >
                  <div>{{ formatPrice(props.item.costPrice) }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Cost Price
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.costPrice"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.regularPrice="props">
                <v-edit-dialog
                    :return-value.sync="props.item.regularPrice"
                    large
                    persistent
                    @save="saveRegularPrice(props)"
                    @cancel="cancel"
                >
                  <div>{{ formatPrice(props.item.regularPrice) }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Regular Price
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.regularPrice"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.salePrice="props">
                <v-edit-dialog
                    :return-value.sync="props.item.salePrice"
                    large
                    persistent
                    @save="savePromoPrice(props)"
                    @cancel="cancel"
                >
                  <div>{{ formatPrice(props.item.salePrice) }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Promotion Price
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.salePrice"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.qty="{ item }">
                <span>{{item.Product.ProductLocationJoins.find(x => x.locationId===getBranch)?(item.Product.ProductLocationJoins.find(x => x.locationId===getBranch).manageStock?'🟢':'🔴'):'🔴'}} {{item.Product.ProductLocationJoins.find(x => x.locationId===getBranch)?item.Product.ProductLocationJoins.find(x => x.locationId===getBranch).available:0}}</span>
              </template>
              <template v-slot:item.Product.type="{ item }">
                <span>{{item.Product.type.length>0?`${item.Product.type[0].toUpperCase()}${item.Product.type.slice(1)}`:item.Product.type}}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1" v-if="isAllowed('product', 'u')" fab x-small color="info" @click="rowClick(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mr-1" v-if="isAllowed('product', 'editLocations')" :loading="item.loadingLocations" fab x-small color="success" @click="showLocations(item)">
                  <v-icon>mdi-warehouse</v-icon>
                </v-btn>
                <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_CLOUD_PRINT')==='true'" class="mr-1" fab x-small color="info" @click="openCloudPrintBarcodeDialog()"><v-icon>mdi-cloud-print</v-icon></v-btn>
                <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true'" class="mr-1" fab x-small color="info" @click="printDirect('productLabel', {id: item.productId})"><v-icon>mdi-printer</v-icon></v-btn>
                <v-btn class="mr-1" fab x-small color="warning" @click="printPreview('productLabel', {id: item.productId})"><v-icon>mdi-printer-eye</v-icon></v-btn>
                <v-btn v-if="getGlobalValue('productSerialShowOnProducts')==='true'" class="mr-1" fab x-small color="info" :loading="item.loadingSerials" @click="viewSerials(item)"><v-icon>mdi-barcode</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col v-if="showFilters" cols="3">    
          <!--Pagination Bar-->
          <!-- <v-card class="" style="user-select: none; position: fixed; bottom: 0; z-index: 100;"> -->
          <v-card class="px-5 pb-5" style="position: fixed;">
            <v-card-title class="d-flex flex-row">
              Filters
              <v-btn class="ml-3" color="warning" small @click="resetFilters">Clear Filters</v-btn>
            </v-card-title>
            <v-form :disabled="tableLoading">
              <span class="d-flex flex-column justify-center">
                <v-autocomplete
                    v-model="queryData.brands"
                    item-text="name"
                    item-value="id"
                    :items="brands"
                    label="Brands"
                    multiple outlined dense clearable
                    @change="searchProducts"
                    class="mx-1"
                    style="max-width: 300px;"
                >
                  <template #selection="{ item }">
                    <v-chip color="info" class="mt-1" close @click:close="deleteChip(item, queryData.brands)" style="font-size: 12px; padding: 0 8px 0 8px; height: 16px;">{{item.name}}</v-chip>
                  </template>
                </v-autocomplete>
                <!--                <v-autocomplete-->
                <!--                    v-model="queryData.categories"-->
                <!--                    item-text="familyName"-->
                <!--                    item-value="id"-->
                <!--                    :items="categories"-->
                <!--                    label="Categories"-->
                <!--                    multiple outlined dense clearable-->
                <!--                    @change="searchProducts"-->
                <!--                    class="mx-1"-->
                <!--                    style="max-width: 300px;"-->
                <!--                >-->
                <!--                  <template #selection="{ item }">-->
                <!--                    <v-chip color="info" class="mt-1" close @click:close="deleteChip(item, queryData.categories)" style="font-size: 12px; padding: 0 8px 0 8px; height: 16px;">{{item.name}}</v-chip>-->
                <!--                  </template>-->
                <!--                </v-autocomplete>-->
                <treeselect
                    :multiple="true"
                    :clearable="true"
                    :searchable="true"
                    :flat="true"
                    :autoSelectDescendants="true"
                    :autoDeselectDescendants="true"
                    :clearOnSelect="true"
                    v-model="queryData.categories"
                    :options="categories"
                    @input="searchProducts"
                    placeholder="Categories"
                    openDirection="top"
                    style="max-width: 300px; min-height: 50px;"
                />
                <v-autocomplete
                    v-model="queryData.tags"
                    item-text="name"
                    item-value="id"
                    :items="tags"
                    label="Tags"
                    multiple outlined dense clearable
                    @change="searchProducts"
                    class="mx-1"
                    style="max-width: 300px;"
                >
                  <template #selection="{ item }">
                    <v-chip color="info" class="mt-1" close @click:close="deleteChip(item, queryData.tags)" style="font-size: 12px; padding: 0 8px 0 8px; height: 16px;">{{item.name}}</v-chip>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="queryData.supplier"
                    item-text="name"
                    item-value="id"
                    :items="suppliers"
                    label="Supplier"
                    outlined dense clearable
                    @change="searchProducts"
                    class="mx-1"
                    style="max-width: 300px;"
                />
              </span>
              <span class="d-flex flex-row justify-center">
                <v-range-slider min="0" :max="maxPrice" class="ml-2" style="max-width: 500px;" v-model="queryData.priceRange" dense @change="searchProducts">
                  <template v-slot:prepend>
                    <v-chip color="info" small>
                      <span>${{queryData.priceRange[0]}}</span>
                      <template>
                        <v-edit-dialog @close="searchProducts">
                          <v-icon class="ml-2" x-small>mdi-cog</v-icon>
                          <template v-slot:input>
                            <v-text-field
                                v-model.lazy="queryData.priceRange[0]"
                                label="Min Price"
                                type="number"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                    </v-chip>
                  </template>
                  <template v-slot:append>
                    <v-chip color="info" small>
                      <span>${{queryData.priceRange[1]}}</span>
                      <template>
                        <v-edit-dialog @close="searchProducts">
                          <v-icon class="ml-2" x-small>mdi-cog</v-icon>
                          <template v-slot:input>
                            <v-text-field
                                v-model.lazy="queryData.priceRange[1]"
                                label="Max Price"
                                type="number"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                    </v-chip>
                  </template>
                </v-range-slider>
              </span>
              <span class="d-flex flex-column justify-center">
                <span class="d-flex flex-row">
                  <v-select outlined dense label="Sort By" style="max-width: 200px; border-bottom-right-radius: 0; border-top-right-radius: 0;" return-object :items="sorting" v-model="customSort" @change="updateCustomSort"/>
                  <v-btn class="mr-2" @click="changeOrder" :disabled="tableLoading" height="40" min-width="1" outlined style="border-bottom-left-radius: 0; border-top-left-radius: 0;" text color="info"><v-icon>{{queryData.order?`mdi-sort-${queryData.sort==="name"?"alphabetical-":""}ascending`:`mdi-sort-${queryData.sort==="name"?"alphabetical-":""}descending`}}</v-icon></v-btn>
                  <v-select outlined dense label="List Size" style="max-width: 100px;" return-object :items="limits" v-model="queryData.limit" @change="searchProducts"/>
                </span>
                <v-pagination
                  :disabled="tableLoading"
                  @input="searchProducts('pageChange')"
                  v-model="queryData.page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                />
              </span>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="locationsDialog" width="600">
      <v-card v-if="selectedProduct">
        <v-card-title class="d-flex flex-column">
          <div>
            <span>Location Quantities for</span>
          </div>
          <div class="text-center">
            <h4>{{selectedProduct.Product.name}}</h4>
            <h5>SKU: {{selectedProduct.Product.sku}}</h5>
            <h5>Total In Stock: {{selectedProduct.totalQuantity}}</h5>
            <h5>Total Available: {{selectedProduct.totalAvailable}}</h5>
<!--            <h5>New Pending Total: {{calculateTotal()}}</h5>-->
            <h5 v-if="selectedProduct.deliveryValues.length>0">Pending Delivery: {{selectedProduct.deliveryValues[0].quantity}}</h5>
          </div>
        </v-card-title>
        <v-card-text class="text-center red--text">THIS IS DEPRECATED. PLEASE REFRAIN FROM ADJUSTING QUANTITIES HERE UNLESS IT IS ABSOLUTELY NECESSARY. A REPLACEMENT FOR THIS WILL BE ADDED SOON. THIS WILL REMAIN FOR THE TIME BEING FOR USERS TO SEE QUANTITIES IN ALL LOCATIONS EASILY.</v-card-text>
        <v-card-text>
          <span v-if="selectedProduct.changeType!=='tempQuantity'">
            <div v-for="(branch, i) of selectedProduct.locations" :key="i">
              <hr>
              <span>
                <span>
                  <b>{{branch.manageStock?'🟢':'🔴'}} {{branch.name}}</b> (Available: {{branch.available}}, Physical: {{branch.quantity}})
                </span>
                <span>
                  <v-row>
                    <v-col v-for="(location, j) of branch.rooms" :key="j" md="6">
                      <span>
                        <v-checkbox @change="toggleLocationQuantityEdit" v-model="location.enabled" label="Edit Location Quantity"/>
                      </span>
                      <v-text-field :disabled="!location.enabled || selectedProduct.changeType==='tempQuantity'" @change="changeQty(location, 'quantity')" class="mx-2" :label="`${location.name} QTY`" v-model="location.quantity" type="number"/>
                    </v-col>
                  </v-row>
                </span>
              </span>
            </div>
          </span>
<!--          <v-row v-if="selectedProduct.changeType!=='tempQuantity'">-->
<!--            <v-col>-->
<!--              <v-checkbox-->
<!--                  v-model="selectedProduct.manageStock"-->
<!--                  label="Manage Stock?"-->
<!--                  outlined-->
<!--                  class="mb-2"-->
<!--                  dense-->
<!--                  hint="Uncheck this if you want to ignore quantity constraints."-->
<!--                  persistent-hint-->
<!--              />-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row>-->
<!--            <v-col>-->
<!--              <v-radio-group-->
<!--                  style="margin-top: -10px;"-->
<!--                  label="Reason"-->
<!--                  v-model="selectedProduct.changeType"-->
<!--                  @change="productLocationChange"-->
<!--                  dense-->
<!--                  column-->
<!--              >-->
<!--                <v-radio-->
<!--                    :key=i v-for="(type, i) of computedQuantityChangeTypes"-->
<!--                    :label="type.name"-->
<!--                    :value="type.value"-->
<!--                />-->
<!--              </v-radio-group>-->
<!--            </v-col>-->
<!--            <v-col>-->
<!--              <span v-if="selectedProduct.changeType==='tempQuantity'">-->
<!--                <p class="red&#45;&#45;text text-pre-line">{{ "CALCULATED VALUE\nThis will auto-calculate the difference of already sold with the new quantity and fill in the new value.Managed Stock is also automatically enabled." }}</p>-->
<!--              </span>-->
<!--              <span v-if="selectedProduct.changeType==='absolute'">-->
<!--                <p class="red&#45;&#45;text text-pre-line">{{ "OVERWRITTEN VALUE\nThis is used to overwrite the current value and assumes the current value as faulty.Stock reports would disregard the old value." }}</p>-->
<!--&lt;!&ndash;                <span>Current Value: {{calculateExcludedTotal()}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <br>&ndash;&gt;-->
<!--&lt;!&ndash;                <span>New Value: {{calculateTotal()}}</span>&ndash;&gt;-->
<!--              </span>-->
<!--              <span v-if="selectedProduct.changeType==='relative'">-->
<!--                <p class="red&#45;&#45;text text-pre-line">{{ "ADJUSTED / CALCULATED VALUE\nThis is used to adjust the current value by the inputted value.This can be used to increment or decrement the current value." }}</p>-->
<!--&lt;!&ndash;                <span>Current Value: {{calculateExcludedTotal()}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <br>&ndash;&gt;-->
<!--&lt;!&ndash;                <span>New Value: {{parseInt(calculateExcludedTotal())+parseInt(calculateTotal())}}</span>&ndash;&gt;-->
<!--              </span>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row>-->
<!--            <v-col>-->
<!--              <v-textarea outlined v-model="selectedProduct.notes" label="Notes"/>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row>-->
<!--            <v-col class="d-flex flex-row justify-center">-->
<!--              <v-btn color="info" v-if="selectedProduct.Product.updateLog && selectedProduct.Product.updateLog.length>0" @click="locationsDialogShowLog=!locationsDialogShowLog">{{locationsDialogShowLog?'Hide':'Show'}} Logs</v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <div v-if="selectedProduct.Product.updateLog">-->
<!--            <div v-if="locationsDialogShowLog">-->
<!--              <v-card @click="logClickAction(log)" v-for="(log, l) in selectedProduct.Product.updateLog" :key="l" elevation="0" outlined class="mb-1">-->
<!--                <v-card-text>-->
<!--                  <v-row>-->
<!--                    <v-col class="d-flex flex-column">-->
<!--                      <span v-if="log.notes">Notes: <b>{{log.notes}}</b></span>-->
<!--                      <span>QTY: {{log.qtyBefore?log.qtyBefore:0}}<v-icon>mdi-arrow-right-bold</v-icon>{{log.qtyAfter}}</span>-->
<!--                      <span>Reason: <b>{{quantityChangeTypes.find(x => x.value===log.changeType).name}}</b></span>-->
<!--                      <span v-if="log.rrId">Receiving Report #{{log.rrId}}</span>-->
<!--                    </v-col>-->
<!--                    <v-col class="d-flex flex-column text-right">-->
<!--                      <span>{{utils.parseDate(log.updatedAt, 2)}} {{utils.formatTime(log.updatedAt)}}</span>-->
<!--                      <span>Modified By: <b>{{lookupUsername(log.userId)}}</b></span>-->
<!--                      <span>Location: <b>{{lookupLocation(log.locationId)}}</b></span>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-card-text>-->
<!--              </v-card>-->
<!--            </div>-->
<!--          </div>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" @click="hideLocations" :disabled="locationsDialogPending" text>Cancel</v-btn>
          <v-btn :disabled="!selectedProduct.valid" color="success" :loading="locationsDialogPending" @click="saveProductQuantities">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="printBarcodeDialog.isOpen" width="600">
      <v-card>
        <v-card-title class="d-flex flex-column">Print Barcodes for {{printBarcodeDialog.productType}} Product #{{printBarcodeDialog.productId}}</v-card-title>
        <v-card-text>
          <span v-if="printBarcodeDialog.productType=='variable'">
            <v-radio-group v-model="printBarcodeDialog.idToPrint" row>
              <v-radio v-for="item of printBarcodeDialog.productVariations" :key="item.id" :label="'ID: '+item.id+', Name: '+item.name" :value="item.id"/>
            </v-radio-group>          
          </span>
          <v-text-field class="pt-4" outlined label="Quantity to Print" type="number" min='1' :max="printBarcodeDialog.maxQty" v-model="printBarcodeDialog.quantity"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" @click="closePrintBarcodeDialog()" text>Cancel</v-btn>
          <v-btn :loading="printBarcodeDialog.loading" :disabled="printBarcodeDialog.quantity<1||printBarcodeDialog.quantity>printBarcodeDialog.maxQty||!printBarcodeDialog.productId||!printBarcodeDialog.idToPrint" color="success" @click="attemptPrintBarcode()">Print</v-btn>
          <v-btn :loading="printPreviewDialog.loading" color="success" @click="printPreview('productLabel', {id:printBarcodeDialog.productId})">Preview</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="printPreviewDialog.isOpen"
      width="11in"
      scrollable
    >
    <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <span v-html="printPreviewDialog.data.job.htmlToPrint"></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="800" v-model="productSerialsDialog.isOpen">
      <v-card v-if="productSerialsDialog.selected">
        <v-card-title>
          <span>{{ serialNaming.plural }} for Product #{{productSerialsDialog.selected.productId}}</span>
          <v-btn v-if="getGlobalValue('productSerialCreateOnProducts')==='true'" fab x-small color="info" class="ml-2" @click="openCreateSerial"><v-icon>mdi-plus</v-icon></v-btn>
          <v-progress-circular class="ml-2" indeterminate color="success" v-if="productSerialsDialog.loading"/>
        </v-card-title>
        <v-card-text>
          <v-text-field append-outer-icon="mdi-magnify" outlined dense v-model="productSerialsDialog.search" clearable/>
          <v-data-table
              :items="productSerialsDialog.data"
              :headers="productSerialsDialog.headers"
              class="elevation-0"
              :search="productSerialsDialog.search"
          >
            <template v-slot:item.locationId="{ item }">
              <span>{{lookupLocation(item.locationId) || 'None'}}</span>
            </template>
            <template v-slot:item.customerId="{ item }">
              <span>{{item.customerId?`Customer ${item.customerId}`:'None'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <span class="d-flex flex-row">
                <v-btn :disabled="productSerialsDialog.loading" @click="deleteSerial(item)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
                <v-btn class="ml-2" @click="$router.push({path: '/products/serial/'+item.serial})" color="success" fab x-small><v-icon>mdi-history</v-icon></v-btn>
              </span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productSerialsDialog.createOpen" width="500">
      <v-card v-if="productSerialsDialog.selected">
        <v-card-title>Create {{serialNaming.singular}} for Product #{{productSerialsDialog.selected.productId}}</v-card-title>
        <v-card-text>
          <v-text-field dense outlined v-model="productSerialsDialog.create" :label="serialNaming.singular"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createSerial" :disabled="!productSerialsDialog.create||productSerialsDialog.create.length===0||productSerialsDialog.create.trim().length===0" :loading="productSerialsDialog.createLoading" color="success">Create</v-btn>
          <v-btn @click="closeCreateSerial" color="error">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
<script>
  import axios from 'axios';
  import { mapGetters, mapMutations } from 'vuex'
  import utils from "./../../plugins/helpers"
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    components: { Treeselect },
    data () {
      return {
        showFilters: false,
        utils: utils,
        init: true,
        updatingRoute: false,

        queryData: {
          searchTerm: '',
          page: 1,
          limit: {text: "15", value: 15},
          order: true,
          sort: "name",
          brands: [],
          categories: [],
          tags: [],
          priceRange: [0, 0],
          supplier: null
        },

        customSort: null,

        limits: [{text: "5", value: 5},{text: "10", value: 10},{text: "15", value: 15},{text: "All", value: -1}],
        sorting: [{text: "Released", value: "released"}, {text: "Popularity", value: "popularity"}],
        totalVisible: 10,
        pageCount: 1,
        maxPrice: 0,

        tableTitle: '',
        tableLoading: false,
        numProducts: 0,

        quantityChangeTypes: [
          {name: "Incorrect Quantity", value: "absolute"},
          {name: "Adjusting Quantity", value: "relative"},
          {name: "No longer using temporary quantity", value: "tempQuantity"},
          {name: "Receiving Report Sealed", value: "rrSeal"},
          {name: "Receiving Report Voided", value: "rrVoid"}
        ],

        headers: [
          {text: 'DB ID', align: 'start', value: 'productId', literal: "id"},
          { text: 'Name', value: 'Product.name', literal: "name"},
          { text: 'SKU', value: 'Product.sku', literal: "sku"},
          { text: 'Brand', value: 'Product.Brand.name', literal: null, sortable: false},
          { text: 'Reg Price', value: 'Product.regularPrice', literal: "regularPrice"},
          { text: 'Alt Price', value: 'Product.salePrice', literal: "salePrice"},
          { text: 'Type', value: 'Product.type', literal: "type"},
          { text: 'Actions', value: 'actions', literal: null, sortable: false},
        ],

        tableOptions: {
          sort: "Product.name",
          order: false
        },

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        loader: true,
        singular: "Product",
        singularLower: "product",
        plural: "Products",
        pluralLower: "products",
        formRules: {
          requiredRule: [v => !!v || 'This field is required.'],
          max25chars: [v => v.length <= 25 || 'Input too long!'],
          emailRules: [
            v => !!v || 'This field is required.',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid.',
          ],
          phoneRules: [
            v => !!v || 'This field is required.',
            v => (v && v.length == 7) || 'Phone must be 7 characters.',
            v => /\d\d\d\d\d\d\d/.test(v) || 'Phone number cannot contain letters.',
          ],
        },

        quantityRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],

        products: [],
        suppliers: [],
        brands: [],
        categories: [],
        tags: [],
        locations: [],

        groupSearch: "",
        filteredGroup: [],
        productGrouping: "supplier",

        variableProductLocationRadio: null,

        locationsDialog: false,
        selectedProduct: null,
        locationsDialogPending: false,
        locationsDialogShowLog: false,
        printBarcodeDialog: {
          isOpen: false,
          productId: null,
          idToPrint: null,
          productType: null,
          productVariations: [],
          quantity: null,
          loading: false,
          maxQty: 50,
        },
        printPreviewDialog: {
          isOpen: false,
          data: '',
          loading: false,
        },

        productSerialsDialog: {
          isOpen: false,
          data: null,
          selected: null,
          loading: false,
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Serial', value: 'serial', sortable: true},
            { text: 'Branch', value: 'locationId', sortable: true},
            { text: 'Customer', value: 'customerId', sortable: true},
            { text: 'Created', value: 'createdAt', sortable: true},
            { text: 'Actions', value: 'actions', sortable: false},
          ],
          search: "",
          create: '',
          createLoading: false,
          createOpen: false
        }
      }
    },
    watch:{
      $route: {
        handler: async function(current){
          if(this.updatingRoute) return;

          let oldQuery = this.getURLQuery(current.query);
          if(Object.keys(oldQuery).length===0){
            this.init = true;
          }

          this.queryData.searchTerm = oldQuery.val||'';
          this.queryData.page = oldQuery.page||1;
          this.queryData.limit = this.limits.find(x => x.value === oldQuery.limit)||this.limits[2];
          this.queryData.sort = oldQuery.sort||"name";
          this.queryData.order = oldQuery.order||true;
          this.queryData.brands = oldQuery.brands||[];
          this.queryData.categories = oldQuery.categories||[];
          this.queryData.supplier = oldQuery.supplier||null;
          this.queryData.tags = oldQuery.tags||[];
          this.queryData.priceRange = oldQuery.priceRange||[0, 0];
          this.maxPrice = this.queryData.priceRange[1];
          if(this.maxPrice!==0){
            this.init = false;
          }

          await this.searchProducts('pageChange');
        },
        deep: true
      }
    },
    async created(){
      try {
        this.init = true;

        if(this.getGlobalValue('replaceProductSerialsWithIMEI')==='true'){
          let found = this.productSerialsDialog.headers.find(x => x.value==='serial');
          if(found) found.text = this.serialNaming.singular
        }

        this.limits = this.getGlobalValue("paginationPageLimitsProducts")?.split(",").map(x => {
          return {text: x==-1?'All':x, value: parseInt(x)}
        }) || this.limits;

        let res = await axios.get(`${this.getEndpoint}/api/suppliers`);
        if(res.data.error) throw res.data.error
        this.suppliers = res.data.data;

        res = await axios.get(`${this.getEndpoint}/api/brands`);
        if(res.data.error) throw res.data.error
        this.brands = res.data.data

        res = await axios.get(`${this.getEndpoint}/api/productcategories/byFamilies`);
        if(res.data.error) throw res.data.error
        this.categories = res.data.data

        res = await axios.get(`${this.getEndpoint}/api/producttags`);
        if(res.data.error) throw res.data.error
        this.tags = res.data.data

        let rooms = await axios.get(`${this.getEndpoint}/api/locations/groupNoSystem`);
        if(rooms.data.error) throw rooms.data.error
        this.locations = rooms.data.data;

        if(!this.isAllowed("seeAllBranchQuantities")){
          this.locations = this.locations.filter(x => this.getAllowedBranches.find(y => y.id===x.id));
        }

        let oldQuery = this.getURLQuery();

        this.queryData.searchTerm = oldQuery.val||'';
        this.queryData.page = oldQuery.page||1;
        this.queryData.limit = this.limits.find(x => x.value === oldQuery.limit)||this.limits[2];
        this.queryData.sort = oldQuery.sort||"name";
        this.queryData.order = oldQuery.order||true;
        this.queryData.brands = oldQuery.brands||[];
        this.queryData.categories = oldQuery.categories||[];
        this.queryData.supplier = oldQuery.supplier||null;
        this.queryData.tags = oldQuery.tags||[];
        this.queryData.priceRange = oldQuery.priceRange||[0, 0];
        this.maxPrice = this.queryData.priceRange[1];
        if(this.maxPrice!==0){
          this.init = false;
        }

        await this.searchProducts('pageChange');

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally{
        this.init = false;
        this.loader = false
      }
    },
    mounted() {
      if(this.getGlobalValue("showQuantityOnProductIndex")==="true" && this.isAllowed("product", "viewQuantity")){
        this.headers.splice((this.headers.length-1), 0, { text: 'Avail QTY', value: 'qty', literal: null, sortable: false})
      }

      if(this.getGlobalValue("VEC_PRODUCT_ALT_PRICE_LABEL") !== ""){
        this.headers.splice
      }
      this.showFilters = localStorage.getItem('showProductTableFilters')==="true" || false
    },
    computed: {
      ...mapGetters(['lookupUsername', 'lookupLocation', 'getEndpoint', 'isAllowed', 'getPerms', "getBranch", "getGlobalValue", "getAllowedBranches", 'serialNaming']),
      computedQuantityChangeTypes: function(){
        let ignored = ['rrSeal', 'rrVoid']
        return this.quantityChangeTypes.filter(type => (type.value!=='tempQuantity' || (type.value==='tempQuantity' && this.selectedProduct.Product.metadata.tempQuantity===true)) && !ignored.includes(type.value))
      }
    },
    methods: {
      ...mapMutations([
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
      ]),
      updateShowHideFilters(){
        localStorage.setItem('showProductTableFilters', this.showFilters)
      },
      loadProducts(prods, item=null){
        this.products = prods

        if(item){
          this.tableTitle = `Showing ${prods.length} Products from ${this.productGrouping==='supplier'?"Supplier":"Brand"}: ${this.productGrouping==='supplier'?item.name:item.name}`

          if(this.productGrouping==='supplier' && this.headers.findIndex(n => n.text==="CP")<0){
            let rpi = this.headers.findIndex(x => x.text==="RP");
            if(rpi>=0){
              this.headers.splice(rpi, 0, { text: 'CP', value: 'costPrice' });
            }
          }
        }
        else{
          this.tableTitle = `Showing ${this.numProducts} Products`

          let cpi = this.headers.findIndex(x => x.text==="CP");
          if(cpi>=0){
            this.headers.splice(cpi, 1);
          }
        }

        if(!this.isAllowed('product', 'viewCostPrice')){
          let c = this.headers.findIndex(x=>x.text =='CP')
          this.headers.splice(c,1)
        }
        if(!this.isAllowed('product', 'editLocations')){
          let c = this.headers.findIndex(x=>x.text =='Actions')
          this.headers.splice(c,1)
        }
      },
      async searchProducts(modifier=''){
        try{
          this.tableLoading = true;

          if(modifier!=='pageChange'){
            this.queryData.page=1;
          }

          let pendingCategorySelections = [];
          for(let categoryId of this.queryData.categories){
            let children = this.categories.filter(x => x.parent===categoryId);
            while(children.length>0){
              let child = children[0];
              if(pendingCategorySelections.indexOf(child.id)<0){
                pendingCategorySelections.push(child.id);
              }
              let newChildren = this.categories.filter(x => x.parent===child.id);
              children.concat(newChildren);
              children.splice(0, 1);
            }
          }
          this.queryData.categories = Array.from(new Set(this.queryData.categories.concat(pendingCategorySelections)));

          let paginationData = {
            val: this.queryData.searchTerm,
            page: this.queryData.page,
            limit: this.queryData.limit.value,
            sort: this.queryData.sort,
            order: this.queryData.order,
            brands: this.queryData.brands,
            categories: this.queryData.categories,
            supplier: this.queryData.supplier||'',
            tags: this.queryData.tags,
            priceRange: this.queryData.priceRange[0]===-1?null:this.queryData.priceRange,
            init: this.init,
            includeQuantity: this.getGlobalValue("showQuantityOnProductIndex")==="true" && this.isAllowed("product", "viewQuantity")
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");

          let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllNoVariables?${uriFields}`);

          if(res.data.error) throw res.data.error
          let transformed = res.data.data.map(x=>{return{
            Product: x,
            productId: x.id
          }})

          this.pageCount = res.data.pages;
          this.numProducts = res.data.numProducts;
          this.maxPrice = res.data.maxPrice;

          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};

          if(paginationData.init && paginationData.priceRange[0]===0 && paginationData.priceRange[1]===0){
            delete newQuery.init;
            newQuery.priceRange = [0, this.maxPrice];
            this.queryData.priceRange = [0, this.maxPrice];
            this.updatingRoute = true;
            if(this.$route.path===`/products`) await this.$router.replace({path: "/products", query: newQuery});
            this.updatingRoute = false;
          }
          else{
            if(!paginationData.init){
              delete newQuery.init;
              let shouldUpdate = false;
              if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;

              if(!shouldUpdate){
                for(let key of Object.keys(newQuery)){
                  if(shouldUpdate) break;

                  if(Array.isArray(newQuery[key])){
                    if(newQuery[key].length!==oldQuery[key].length){
                      shouldUpdate = true;
                      break;
                    }
                    for(let i=0;i<newQuery[key].length-1;i++){
                      if(newQuery[key][i]!==oldQuery[key][i]){
                        shouldUpdate = true;
                        break;
                      }
                    }
                  }
                  else if(newQuery[key]!==oldQuery[key]){
                    shouldUpdate = true;
                  }
                }
              }

              if(shouldUpdate){
                this.updatingRoute = true;
                if(this.$route.path===`/products`) await this.$router.push({path: "/products", query: newQuery});
                this.updatingRoute = false;
              }
            }

          }

          this.loadProducts(transformed)
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.tableLoading = false;
        }
      },
      async resetFilters(){
        try{
          this.queryData = {
            searchTerm: '',
            page: 1,
            limit: this.limits[2],
            order: true,
            sort: "name",
            brands: [],
            categories: [],
            tags: [],
            priceRange: [0, this.maxPrice],
            supplier: null
          };
          await this.searchProducts();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.supplier) oldQuery.supplier = oldQuery.supplier?parseInt(oldQuery.supplier):null;
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        if(oldQuery.brands){
          if(!Array.isArray(oldQuery.brands)){
            oldQuery.brands = [oldQuery.brands];
          }
          oldQuery.brands = oldQuery.brands.map(x => parseInt(x))
        }
        if(oldQuery.tags){
          if(!Array.isArray(oldQuery.tags)){
            oldQuery.tags = [oldQuery.tags];
          }
          oldQuery.tags = oldQuery.tags.map(x => parseInt(x))
        }
        if(oldQuery.categories){
          if(!Array.isArray(oldQuery.categories)){
            oldQuery.categories = [oldQuery.categories];
          }
          oldQuery.categories = oldQuery.categories.map(x => parseInt(x))
        }
        if(oldQuery.priceRange){
          oldQuery.priceRange = oldQuery.priceRange.map(x => parseInt(x))
        }

        return oldQuery;
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async saveName(e) {
        try {
          let res = await axios.put(`${this.getEndpoint}/api/products/updateName`, {pId: e.item.id, newName: e.item.name});
          if(res.data.error) throw res.data.error
          this.snack('Product Saved')
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async saveRegularPrice(e) {
        try {
          let res = await axios.put(`${this.getEndpoint}/api/products/updateRegularPrice`, {pId: e.item.productId, newPrice: e.item.regularPrice});
          if(res.data.error) throw res.data.error
          this.snack('Product Saved')
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async savePromoPrice(e) {
        try {
          let res = await axios.put(`${this.getEndpoint}/api/products/updatePromoPrice`, {pId: e.item.productId, newPrice: e.item.salePrice});
          if(res.data.error) throw res.data.error
          this.snack('Product Saved')
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      // async saveCostPrice(e) {
      //   try {
      //     console.log(e.item.costPrice)
      //     if(!this.$route.query.supplier) throw "No Supplier Id"
      //     let res = await axios.put(`${this.getEndpoint}/api/products/updateCostPrice`, {sId: this.$route.query.supplier, pId: e.item.productId, newPrice: e.item.costPrice});
      //     if(res.data.error) throw res.data.error
      //     this.snack('Product Saved')
      //   } catch (error) {
      //     console.error(error)
      //     this.snack(error.msg || error.msg?.message || error, "error");
      //   }
      // },
      async saveProductQuantities(){
        try{
          this.locationsDialogPending = true;
          if(!this.selectedProduct) throw "No product selected."

          // if(this.selectedProduct.Product.type==='simple'){
          let obj = {
            id: this.selectedProduct.Product.id,
            type: this.selectedProduct.Product.type,
            locations: this.selectedProduct.locations,
            changeType: 'absolute',
            notes: this.selectedProduct.notes || '',
            manageStock: this.selectedProduct.manageStock
          }
          // }
          // else if(this.selectedProduct.Product.type==='variable'){
          //   if(!this.variableProductLocationRadio) throw "No variation selected."
          //
          //   obj = {
          //     id: this.variableProductLocationRadio.id,
          //     type: this.variableProductLocationRadio.type,
          //     locations: this.variableProductLocationRadio.locations
          //   }
          // }
          let res = await axios.post(`${this.getEndpoint}/api/products/updateLocations`, obj);
          if(res.data.error) throw res.data.error
          this.selectedProduct.Product.manageStock = obj.manageStock;
          this.snack("Quantities Updated");
          this.hideLocations();
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally{
          this.locationsDialogPending = false;
        }
      },
      cancel() {
        this.snack('Cancelled')
      },
      calculateTotal(){
        let total = 0;

        for(let branch of this.selectedProduct.locations){
          for(let room of branch.rooms){
            if(room.enabled) total+=room.quantity;
          }
        }

        return total;
      },
      calculateExcludedTotal(){
        let total = this.selectedProduct.totalQuantity;

        for(let branch of this.selectedProduct.locations){
          for(let room of branch.rooms){
            if(!room.enabled) total-=room.quantity;
          }
        }

        return total;
      },
      productLocationChange(){
        this.selectedProduct.valid = this.checkProductLocationDialog();
        this.$forceUpdate();
        this.calculateTotal();
        this.calculateExcludedTotal();
      },
      checkProductLocationDialog(){
        if(!this.selectedProduct) return false;
        if(!this.selectedProduct.changeType) return false;
        for(let branch of this.selectedProduct.locations){
          for(let room of branch.rooms){
            if(room.quantity===null || room.quantity===undefined || isNaN(room.quantity)) return false;
          }
        }
        return true;
      },
      toggleLocationQuantityEdit(){
        this.$forceUpdate();
      },
      async showLocations(item){
        try{
          item.loadingLocations = true;
          this.$forceUpdate();

          let getLocations = () => {
            let locations = [];
            for(let branch of this.locations){
              let b = {
                id: branch.id,
                name: branch.name,
                rooms: [],
                available: 0,
                quantity: 0
              }

              for(let room of branch.rooms){
                let obj = {
                  locationId: room.id,
                  name: room.name,
                  quantity: 0,
                  enabled: false
                }
                b.rooms.push(obj);
              }
              locations.push(b);
            }

            return locations;
          }


          // if(item.Product.type==='simple'){
          item.totalQuantity = 0;
          item.totalAvailable = 0;
          item.locations = getLocations();
          item.changeType = 'absolute';
          item.notes = '';
          item.valid = false;
          item.manageStock = item.Product.manageStock;

          let prj = await axios.get(`${this.getEndpoint}/api/products/${item.productId}/onlyLocations`);
          if(prj.data.error) throw prj.data.error

          if(prj.data.data.updateLog){
            item.Product.updateLog = prj.data.data.updateLog;
          }

          let branches = prj.data.data.ProductLocationJoins;
          item.deliveryValues = prj.data.data.DeliveryJoins;

          for(let branch of branches){
            let f1 = item.locations.find(x => x.id===branch.locationId);
            if(f1){
              f1.available = branch.available;
              f1.quantity = branch.quantity;
              f1.manageStock = branch.manageStock;
              item.totalAvailable+=branch.available;
              for(let room of branch.rooms){
                let f2 = f1.rooms.find(x => x.locationId===room.locationId);
                if(f2){
                  f2.quantity = room.quantity;
                  item.totalQuantity+=room.quantity;
                }
              }
            }
          }

          this.selectedProduct = item;
          this.locationsDialog = true;

          this.$forceUpdate();
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally{
          item.loadingLocations = false;
          this.$forceUpdate();
        }
      },
      hideLocations(){
        this.selectedProduct = null;
        this.variableProductLocationRadio = null;
        this.locationsDialog = false;
      },
      changeQty(location, field='quantity'){
        if(location[field]>=0){
          location[field]=parseInt(location[field]);
          this.$forceUpdate();
        }

        this.productLocationChange();
      },
      // async loadProductsFromGroup(item){
      //   try {
      //     this.tableLoading = true
      //
      //     let group = this.productGrouping==='supplier'?'Supplier':'Brand';
      //     let res = await axios.get(`${this.getEndpoint}/api/products/by${group}NoVariations/${item.id}`);
      //     if(res.data.error) throw res.data.error
      //     if(this.productGrouping==="supplier"){
      //       if(!(this.$route.query.supplier && this.$route.query.supplier==item.id)){
      //         await this.$router.replace({path: `/products`, query:{supplier: item.id}});
      //       }
      //     }
      //     else if(this.productGrouping==="brand"){
      //       if(!(this.$route.query.brand && this.$route.query.brand==item.id)){
      //         await this.$router.replace({path: `/products`, query:{brand: item.id}});
      //       }
      //       res.data.data.map(x=>{
      //         x.Product = x
      //         x.productId = x.id
      //       })
      //     }
      //     this.loadProducts(res.data.data, item)
      //   } catch (error) {
      //     console.error(error)
      //     this.snack(error.msg || error.msg?.message || error, "error");
      //   } finally {
      //     this.tableLoading = false
      //   }
      // },
      rowClick(row){
        let addon = '';
        if(row.Product.parent){
          addon = `?variation=${row.productId}`;
        }
        this.$router.push({ path: `/${this.pluralLower}/view/${row.Product.parent?row.Product.parent:row.productId}${addon}`});
      },
      async logClickAction(log){
        if(log.rrId){
          await this.$router.push(`/receiving/view/${log.rrId}`);
        }
      },
      loadNewGroup(){
        this.filteredGroup = this.productGrouping==='supplier'?this.suppliers:this.brands;
      },
      searchGroup(){
        let arr = this.productGrouping==='supplier'?this.suppliers:this.brands;
        let term = this.productGrouping==='supplier'?'name':'name';

        if(this.groupSearch.length===0){
          this.filteredGroup = arr;
          return;
        }

        this.filteredGroup = arr.filter(item => {
          return item[term].toLowerCase().includes(this.groupSearch.toLowerCase());
        });
      },
      async openCloudPrintBarcodeDialog(item){
        try {
          if(item.Product.type==='variable'){
            let res = await axios.get(`${this.getEndpoint}/api/products/variationsForLabelPrinting/${item.productId}`);
            if(res.data.error) throw res.data.error

            this.printBarcodeDialog.productVariations = res.data.data
          }else{
            this.printBarcodeDialog.idToPrint = item.productId
          }
          this.printBarcodeDialog.isOpen = true
          this.printBarcodeDialog.productType = item.Product.type
          this.printBarcodeDialog.productId =  item.productId
          this.printBarcodeDialog.quantity =  0
          this.printBarcodeDialog.loading =  false 
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      closePrintBarcodeDialog(){
        this.printBarcodeDialog.isOpen = false
      },
      async attemptPrintBarcode(){
        try {
          this.printBarcodeDialog.loading = true
          let res = await axios.post(`${this.getEndpoint}/api/print/productLabel/${this.printBarcodeDialog.idToPrint}/${this.printBarcodeDialog.quantity}`);
          if(res.data.error) throw res.data.error
          this.snack("Printing Barcodes.")
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.printBarcodeDialog.loading = false
          this.closePrintBarcodeDialog()
        }
      },
      formatPrice(value){
        if(isNaN(value)) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      async changeOrder(){
        try{
          this.queryData.order = !this.queryData.order;
          await this.searchProducts();
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      async deleteChip(item, array) {
        try{
          array.splice(array.indexOf(item)-1, 1);
          await this.searchProducts()
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      async updateTableOptions(options){
        try{
          if(this.init || this.customSort) return;

          let found = this.headers.find(x => x.value===options.sortBy[0]);
          this.queryData.sort = found?found.literal:undefined;
          this.tableOptions = {
            order: options.sortDesc[0],
            sort: found?found.value:undefined
          }
          this.queryData.order = !options.sortDesc[0];

          this.customSort = null;

          await this.searchProducts();
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      async updateCustomSort(){
        try{
          this.tableOptions = {
            order: undefined,
            sort: undefined
          }

          this.queryData.sort = this.customSort.value;

          await this.searchProducts();

        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      async printPreview(type, metadata){
        try {
          this.printPreviewDialog.loading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          this.printPreviewDialog.data = x.data.data
          this.printPreviewDialog.loading = false
          this.printPreviewDialog.isOpen = true
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closePrintPreviewDialog(){
        this.printPreviewDialog.loading = false
          this.printPreviewDialog.data = ''
          this.printPreviewDialog.isOpen = false
      },
      async printDirect(type, metadata){
        try {
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.close()
          },300)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      async viewSerials(product){
        try {
          product.loadingSerials = true;
          this.productSerialsDialog.selected = product;

          let x = await axios.get(`${this.getEndpoint}/api/products/${product.productId}/serials`)
          if(x.data.error) throw x.data.error
          this.productSerialsDialog.data = x.data.data;

          this.productSerialsDialog.isOpen = true;

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          product.loadingSerials = false;
        }
      },
      closeSerialDialog(){
        this.productSerialsDialog = {
          ...this.productSerialsDialog,
          isOpen: false,
          data: null,
          selected: null
        };
      },
      async createSerial(){
        try {
          this.productSerialsDialog.createLoading = true;

          let obj = {
            serial: this.productSerialsDialog.create.trim()
          }

          let x = await axios.post(`${this.getEndpoint}/api/products/${this.productSerialsDialog.selected.productId}/serials`, obj)
          if(x.data.error) throw x.data.error

          this.productSerialsDialog.data.push(x.data.data);

          this.closeCreateSerial();

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.productSerialsDialog.createLoading = false;
        }
      },
      openCreateSerial(){
        this.productSerialsDialog.createOpen = true;
      },
      closeCreateSerial(){
        this.productSerialsDialog.createOpen = false;
        this.productSerialsDialog.create = '';
      },
      async deleteSerial(serial){
        try {
          this.productSerialsDialog.loading = true;

          let x = await axios.delete(`${this.getEndpoint}/api/products/${this.productSerialsDialog.selected.productId}/serials/${serial.id}`)
          if(x.data.error) throw x.data.error

          if(x.data.data){
            let i = this.productSerialsDialog.data.findIndex(x => x.serial===serial.serial);
            if(i<0) return;
            this.productSerialsDialog.data.splice(i);
          }

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.productSerialsDialog.loading = false;
        }
      }
    }
  }
</script>
